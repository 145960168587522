@import '~app/mixins';

.options {
  position: relative;

  .control {
    height: 26px;
    width: 190px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    background: $color-white;
    padding: 6px 35px 6px 12px;
    color: $color-grey;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.small {
      font-size: 12px;
      height: 26px;

      .iconCaretDown {
        top: 9px;
      }
    }

    .value {
      width: 200px;

      @include text-overflow();
    }

    .iconCaretDown {
      width: 12px;
      height: 9px;
      position: absolute;
      right: 9px;
    }
  }

  .icon {
    width: 22px;
    height: 22px;

    use {
      fill: $color-grey-light;
    }
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    padding: 8px;
    display: none;
    z-index: 2;
    margin-top: 5px;
    width: 600px;
    top: 100%;

    @include primary-border-color();

    .header {
      margin: 15px 0;
      text-align: center;
      font-size: 20px;
      color: $color-blue-dark;
    }

    .section {
      margin-bottom: 30px;

      .subSection {
        margin-top: 16px;
      }

      .title {
        padding-bottom: 4px;
        border-bottom: 1px solid $color-grey;
        font-weight: bold;
        font-size: 14px;
      }

      .description {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .content {
        display: flex;

        .item {
          display: flex;
          flex: 1;

          .label {
            margin: 6px 4px 0 8px;
            white-space: nowrap;
          }

          label {
            margin-right: 15px;

            span {
              padding-left: 4px;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin: 15px 0;

      .btn {
        + .btn {
          margin-left: 8px;
        }
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      left: 40px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);
      background: $color-white;
      top: -3px;

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow+2;
      width: 15px;
      left: 35px;
      background: $color-white;
      top: 0;
    }
  }

  &.open {
    .dropdown {
      display: block;
    }

    .icon use {
      fill: $color-blue-darkest !important;
    }
  }
}
